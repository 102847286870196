<template>
    <div>
        <h1> Projects </h1>
        <p id="projectInfo"> <i class="pi pi-exclamation-triangle" /> <b>Nerd Alert!</b> <i class="pi pi-exclamation-triangle" /> 
            This is the the place I want to show off my stuff! Here are some of my projects I'm proud of. 
            Realizing as I've become more experienced, how basic or inefficient my first projects are, I still 
            want to show them off because I was super proud of them at the time.
        </p>

        <Card class="card">
            <template #title>
                College Pickems
            </template>
            <template #content>
                <img alt="Pickems Website Image" :src="require('@/assets/Pickems.png')">
                <div class="cardContent">
                    It started as a spreadsheet shared between a few friends and I turned it into a website. Every week during the college football season, 
                    10 games are chosen and people have to pick a winner to those games and assign 1-10 confidence points. Ten being very 
                    confident that your chosen team will win and one being the least. There are a maximum of 55 points each week and at the 
                    end of the year the person with the most points wins.
                    <br />
                    <br />
                    <b>Stack:</b> PHP, MySQL
                    <br />
                    <b>Year: </b> 2018 - Current
                </div>
            </template>
            <template #footer>
                <a href="http://www.ianlueninghoener.com/Pickems" target="_blank">
                    <Button icon="pi pi-sign-in" label="Visit" />
                </a>
            </template>
        </Card>
        <Card class="card">
            <template #title>
                The Decider
            </template>
            <template #content>
                <img alt="The Decider Image" :src="require('@/assets/Decider.jpg')">
                <div class="cardContent">
                    This was one of the first things I wrote after I learned to code. Sometimes I have a hard time 
                    making decisions knowing I'd be happy with either outcome. So I wrote a simple program to make 
                    that decision for me.

                    <br />
                    <br />
                    <b>Stack:</b> JavaScript
                    <br />
                    <b>Year: </b> 2015
                </div>
            </template>
            <template #footer>
                <a href="http://www.ianlueninghoener.com/Portfolio/decider.html" target="_blank">
                    <Button icon="pi pi-sign-in" label="Visit" />
                </a>
                <a href="https://bitbucket.org/ianlueninghoener/the-decider/src/master/" target="_blank">
                    <Button icon="pi pi-share-alt" label="Source" class="p-button-info" style="margin-left: 10px;"/>
                </a>
            </template>
        </Card>
        <Card class="card">
            <template #title>
                Elkhorn Valley Family Medicine
            </template>
            <template #content>
                <img alt="EVFM Website Image" :src="require('@/assets/EVFM.jpg')">
                <div class="cardContent">
                    After finishing a web design class in college I convinced my dad that his business needed 
                    a website and that I would be the one who would make it for him. This was the first  
                    professional thing I produced. Besides its dated design and lack of responsiveness, I think 
                    the design has held up pretty well. No framework, just straight HTML, CSS, and JavaScript - 
                    something I think anyone who designs websites should be able to do.

                    <br />
                    <br />
                    <b>Stack:</b> HTML, JavaScript
                    <br />
                    <b>Year: </b> 2013 - Current
                </div>
            </template>
            <template #footer>
                <a href="http://www.elkhornvalleyfamilymedicine.com" target="_blank">
                    <Button icon="pi pi-sign-in" label="Visit" />
                </a>
            </template>
        </Card>
                <Card class="card">
            <template #title>
                        IanLueninghoener.com
                    </template>
            <template #content>
                <TabView :activeIndex="2">
                    <TabPanel header="2012-2016">
                        <Card class="card-in-a-card">
                            <!-- <template #header>
                                <img alt="user header" src="demo/images/usercard.png">
                            </template> -->
                            <!-- <template #title>
                                IanLueninghoener.com
                            </template> -->
                            <template #content>
                                The original ianlueninghoener.com! I was just proud that things displayed. 
                            </template>
                            <template #footer>
                                <a href="http://www.ianlueninghoener.com/Portfolio/V1" target="_blank">
                                    <Button icon="pi pi-sign-in" label="Visit" />
                                </a>
                            </template>
                        </Card>
                    </TabPanel>
                    <TabPanel header="2016 - 2021">
                        <Card class="card-in-a-card">
                            <!-- <template #header>
                                <img alt="user header" src="demo/images/usercard.png">
                            </template> -->
                            <!-- <template #title>
                                IanLueninghoener.com
                            </template> -->
                            <template #content>
                                I had great plans for this version of the website. It was going to have all my content, 
                                and it was going to be colorful and amazing. Basically, I made the navigation for the home 
                                page and JUST the home page and then I never touched it again. I focused all my energy 
                                into the Pickems. I couldn't take the final product in my head and produce it. I just didn't 
                                know how. Wordpress was a thing but never really an option. I was a developer; I made custom 
                                things. Doing a Wordpress website was like taking the easy way out and was something I just 
                                would never do. At this point in time I didn't really understand frameworks and what they 
                                could offer me.
                            </template>
                            <template #footer>
                                <a href="http://www.ianlueninghoener.com/Portfolio/V2/" target="_blank">
                                    <Button icon="pi pi-sign-in" label="Visit" />
                                </a>
                            </template>
                        </Card>
                    </TabPanel >
                    <TabPanel header="2021">
                        <Card class="card-in-a-card">
                            <!-- <template #header>
                                <img alt="user header" src="demo/images/usercard.png">
                            </template> -->
                            <!-- <template #title>
                                IanLueninghoener.com
                            </template> -->
                            <template #content>
                                A lot of what ends up on my personal website comes from what I have learned in school and at work. 
                                Shortly after starting a new job, I was introduced to Vue. What a game changer! I could still 
                                do a custom website, but I could do it quickly and make it look good. This renewed my drive to 
                                make this website what I always wanted it to be!
                            </template>
                            <template #footer>
                                <a href="http://www.ianlueninghoener.com/" target="_blank">
                                    <Button icon="pi pi-sign-in" label="Visit" />
                                </a>
                            </template>
                        </Card>
                    </TabPanel>
                </TabView>
            </template>
        </Card>
        <Card class="card">
            <!-- <template #header>
                <img alt="user header" src="demo/images/usercard.png">
            </template> -->
            <template #title>
                Recipes
            </template>
            <template #content>
                Since I really like to cook, having all my recipes in one place is something I've always wanted 
                to do. URLs changing, bookmarks getting lost, and website ceasing to exist are the most common ways
                for recipes to be lost forever. Writing them down is one option, but I want to be able to share them 
                with family and friends. So making my own online recipe website seemed to be the only way to go.

                <br />
                <br />
                <b>Stack:</b> NodeJS (Vue 3)
                <br />
                <b>Year: </b> 2021
            </template>
            <template #footer>
                <a href="http://www.ianlueninghoener.com/Recipes" target="_blank">
                    <Button icon="pi pi-sign-in" label="Visit" />
                </a>
            </template>
        </Card>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Card from 'primevue/card';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';

export default {
    name: "Projects",
    components: {
        Button,
        Card,
        TabPanel,
        TabView
    }
}
</script>

<style>
    .card {
        width: 85%;
        margin: auto;
        margin-bottom: 10px;
    }
    .card-in-a-card {
        box-shadow: none;
    }
    .card .p-tabview-panels {
        padding: 0 !important;
    }
    .card .p-tabview-panels .p-card-footer{
        text-align: center;
    }
    .p-card-content {
        text-align: left;
    }
    .p-card-content img {
        max-width: 450px;
        width: 50%;
    }
    .cardContent {
        display: inline-block;
        max-width: 50%;
        padding-left: 10px;
        vertical-align: top;
        width: auto;
    }
    #projectInfo {
        margin: auto;
        margin-bottom: 10px;
        width: 80%;
    }

    @media only screen and (max-width: 754px) {
        .p-card-content img {
            display: none;
        }
        .cardContent {
            max-width: 100%;
        }
    }
</style>